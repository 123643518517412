export default {
    /**
     * 全局加载状态
     */
    loading(state) {
        return state.loading
    },

    /**
     * 管理员信息
     */
    admin(state) {
        return state.admin
    }
}