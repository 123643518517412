export default {
    /**
     * 显示全局加载状态
     */
    showLoading({ state, commit, getters }) {
        commit('loading', true)
    },

    /**
     * 隐藏全局加载状态
     */
    hideLoading({ state, commit, getters }) {
        commit('loading', false)
    },

    /**
     * 完成登录
     */
    login({ state, commit, getters }, data) {
        window.localStorage.setItem('token', data.token)
        commit('admin', data.admin)
    },

    /**
     * 退出登录
     */
    logout({ state, commit, getters }) {
        window.localStorage.removeItem('token')
        commit('admin', null)
    }
}