export default {
    /**
     * 更改全局加载状态
     */
    loading(state, status) {
        state.loading = status
    },

    /**
     * 更改管理员信息
     */
    admin(state, data) {
        state.admin = data
    }
}