import Axios from 'axios'
import $store from '@/store'
import $router from '@/router'

import { Message, Notice, Modal } from 'view-design'

if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//"
        + window.location.hostname
        + (window.location.port ? ':' + window.location.port : '');
}

// Instance
const $http = Axios.create({
    baseURL: window.location.origin + "/api"
})

// Request Hook
$http.interceptors.request.use(
    config => {
        if (config.show_loading !== false)
            $store.dispatch('showLoading')

        config.headers.Authorization = window.localStorage.getItem('token')

        return config
    },
    error => {
        Modal.error({
            title: error,
            onOk : () => {
                
            }
        })

        return Promise.reject(error)
    }
)

// Response Hook
$http.interceptors.response.use(
    response => {
        $store.dispatch('hideLoading')

        const res = response.data

        switch (res.status)
        {
            // 操作成功
            case 200:
                res.message && Message.success({ content: res.message, duration: 3 })
                return Promise.resolve(res.data)

            // 操作失败
            case 400:
                res.message &&  Message.error({ content: res.message, duration: 3 })
                return Promise.reject(res)

            // 没有权限
            case 403:
                res.message &&  Message.error({ content: res.message, duration: 3 })
                $router.back()
                return new Promise(() => {})

            // 404
            case 404:
                Modal.error({
                    title   : '404',
                    content : res.message ? res.message : 'Page Not Found',
                    onOk: () => {
                        $router.back()
                    }
                })
                return new Promise(() => {})

            // 发生错误
            case 500:
                Modal.error({
                    title   : 'Error',
                    content : res.message ? res.message : 'System Fail',
                    onOk: () => {
                        $router.back()
                    }
                })
                return new Promise(() => {})

            // 未登录
            case 100:
                res.message &&  Message.warning({ content: res.message, duration: 3 })
                $router.replace('/login?redirect=' + $router.currentRoute.fullPath)
                return new Promise(() => {})

            default:
                Modal.error({
                    title   : 'Unknown Error',
                    content : res,
                    onOk : () => {
                        
                    }
                })

                return new Promise(() => {})
        }
    },
    error => {
        $store.dispatch('hideLoading')
        
        Modal.error({
            title: error,
            onOk : () => {

            }
        })

        return new Promise(() => {})
    }
)

export default $http