<template>
    <Page
        class="text-center"
        show-total
        show-sizer
        show-elevator
        :total="total"
        :current="page"
        :page-size="limit"
        @on-change="changePage"
        @on-page-size-change="changeLimit"
    ></Page>
</template>

<script>
    export default {
        props: {
            total: {
                type : Number,
                default : 0
            }
        },
        data ()
        {
            return {
                page  : this.$route.query.page  ? parseInt(this.$route.query.page)  : 1,
                limit : this.$route.query.limit ? parseInt(this.$route.query.limit) : 10
            }
        },
        methods: {
            changePage(page)
            {
                this.page = page
                this.redirect()
            },
            changeLimit(limit)
            {
                this.limit = limit
                this.redirect()
            },
            redirect()
            {
                let url    = this.$route.path
                let query  = this.$route.query
                let params = []

                for (var i in query)
                {
                    if (i == 'page' || i == 'limit')
                        continue

                    params.push(i + '=' + query[i])
                }

                params = params.join('&')
                url += '?' + params + (params != '' ? '&' : '') + 'page=' + this.page + '&limit=' + this.limit

                this.$router.currentRoute.fullPath == url || this.$router.push(url)
            }
        },
        watch: {
            '$route.query.page'(value)
            {
                this.page = value ? parseInt(value) : 1
            },
            '$route'(value)
            {
                this.$emit('on-change', {
                    page  : this.page,
                    limit : this.limit
                })
            }
        }
    }
</script>