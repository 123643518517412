import $store from '@/store'

export default (auth) => {
	if (auth === false)
		return true

	const admin = $store.getters.admin

	if (!admin)
		return false

	if (!admin.role_id)
		return true

	var access = admin.auth_access
	var action = auth[1]

	if (action)
	{
		var module = auth[0].toLowerCase()
		var action = action.toLowerCase()

		return access[module] && access[module].indexOf(action) !== -1 ? true : false
	}
	else
	{
		var modules = auth[0].split(/,\s?/)
		for (var module of modules)
		{
			module = module.toLowerCase()

			if (access[module])
				return true
		}

		return false
	}
}