<template>
    <Input v-model="keywords" @on-enter="submit">
        <Select
            style="width: 100px"
            slot="prepend"
            v-model="search_key"
            @on-change="submit"
        >
            <Option :value="key" v-for="(label, key) in options" :key="key">{{ label }}</Option>
        </Select>
        <Button slot="append" icon="ios-search" @click="submit"></Button>
    </Input>
</template>

<script>
    export default {
        props: {
            options: {
                type : Object,
                required : true
            }
        },
        data()
        {
            return {
                search_key : this.$route.query.search_key ? this.$route.query.search_key : Object.keys(this.options)[0],
                keywords   : this.$route.query.keywords   ? this.$route.query.keywords   : ''
            }
        },
        methods: {
            redirect()
            {
                var url    = this.$route.path
                var query  = this.$route.query
                var params = ''

                for (var i in query)
                {
                    if (i == 'keywords' || i == 'search_key' || i == 'page')
                        continue

                    params += i + '=' + query[i]
                }

                url += '?' + params + (params != '' ? '&' : '') + 'keywords=' + this.keywords + '&search_key=' + this.search_key


                this.$router.currentRoute.fullPath == url || this.$router.push(url)
            },
            submit()
            {
                this.redirect()
            }
        },
        watch: {
            '$route'(value)
            {
                this.keywords = this.$route.query.keywords ? this.$route.query.keywords : ''
                this.$emit('on-change', {
                    keywords   : this.keywords,
                    search_key : this.search_key
                })
            }
        }
    }
</script>