<template>
    <div class="pos-rel vh100-min">
        <router-view />
        <Spin size="large" fix v-if="$store.getters.loading">
            <Icon type="ios-loading" size="28" class="spin-icon-load"></Icon>
            <div>Loading</div>
        </Spin>
    </div>
</template>

<script>
    export default {
        created()
        {

        }
    }
</script>