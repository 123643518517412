import Vue    from 'vue'
import App    from './App.vue'
import router from './router'
import store  from './store'
import http   from './http'
import Auth   from './auth'
import ViewUI from 'view-design'

import Searcher   from './components/Searcher'
import Pagination from './components/Pagination'

import 'view-design/dist/styles/iview.css'
import './styles/theme.less'
import './styles/common.css'

Vue.use(ViewUI)

Vue.component('Searcher', Searcher)
Vue.component('Pagination', Pagination)

Vue.prototype.$http = http
Vue.prototype.Auth  = Auth

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')