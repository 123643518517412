import Vue       from 'vue'
import VueRouter from 'vue-router'
import routes    from './routes'

import { LoadingBar } from 'view-design'

Vue.use(VueRouter)

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path : '/',
            component : () => import('@/views/Index.vue'),
            children  : routes,
            meta : {
                title: '管理后台'
            }
        },
        {
            path : '/login',
            name : 'Login',
            component : () => import('@/views/Login.vue'),
            meta : {
                title: '登录'
            }
        }
    ]
})

// Before router
router.beforeEach((to, form, next) => {
    
    next()
})

// After router
router.afterEach(route => {
    
})

export default router