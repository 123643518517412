/**
 * 管理后台路由
 */
export default [

    /*
    |--------------------------------------------------------------------------
    | 管理员
    |--------------------------------------------------------------------------
    */
    {
        path: '/admin',
        name: 'admin',
        meta: {
            title: '管理员设置'
        },
        component: () => import('@/views/admin/Index.vue')
    },
    {
        path: '/admin/create',
        name: 'admin-create',
        meta: {
            title: '添加管理员'
        },
        component: () => import('@/views/admin/Form.vue')
    },
    {
        path: '/admin/update/:id',
        name: 'admin-update',
        meta: {
            title: '修改管理员'
        },
        component: () => import('@/views/admin/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 权限角色
    |--------------------------------------------------------------------------
    */
    {
        path: '/auth-role',
        name: 'auth-role',
        meta: {
            title: '权限角色设置'
        },
        component: () => import('@/views/auth-role/Index.vue')
    },
    {
        path: '/auth-role/create',
        name: 'auth-role-create',
        meta: {
            title: '添加权限角色'
        },
        component: () => import('@/views/auth-role/Form.vue')
    },
    {
        path: '/auth-role/update/:id',
        name: 'auth-role-update',
        meta: {
            title: '修改权限角色'
        },
        component: () => import('@/views/auth-role/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 权限动作
    |--------------------------------------------------------------------------
    */
    {
        path: '/auth-action',
        name: 'auth-action',
        meta: {
            title: '权限动作设置'
        },
        component: () => import('@/views/auth-action/Index.vue')
    },
    {
        path: '/auth-action/create',
        name: 'auth-action-create',
        meta: {
            title: '添加权限动作'
        },
        component: () => import('@/views/auth-action/Form.vue')
    },
    {
        path: '/auth-action/update/:id',
        name: 'auth-action-update',
        meta: {
            title: '修改权限动作'
        },
        component: () => import('@/views/auth-action/Form.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 首页
    |--------------------------------------------------------------------------
    */
    {
        path: '/',
        name: 'index',
        meta: {
            title: '控制台'
        },
        component: () => import('@/views/home/Index.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            title: '个人信息'
        },
        component: () => import('@/views/home/Profile.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 规则说明设置
    |--------------------------------------------------------------------------
    */
    {
        path: '/rule-explain',
        name: 'rule-explain',
        meta: {
            title: '规则说明设置'
        },
        component: () => import('@/views/rule-explain/Index.vue')
    },
    {
        path: '/rule-explain/create',
        name: 'rule-explain-create',
        meta: {
            title: '添加规则说明'
        },
        component: () => import('@/views/rule-explain/Form.vue')
    },
    {
        path: '/rule-explain/update/:id',
        name: 'rule-explain-update',
        meta: {
            title: '修改规则说明'
        },
        component: () => import('@/views/rule-explain/Form.vue')
    },
    {
        path: '/rule-explain/set/:id',
        name: 'rule-explain-set',
        meta: {
            title: '编辑规则说明'
        },
        component: () => import('@/views/rule-explain/Set.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 广告图设置
    |--------------------------------------------------------------------------
    */
    {
        path: '/ad',
        name: 'ad',
        meta: {
            title: '所有页面'
        },
        component: () => import('@/views/ad/Index.vue')
    },
    {
        path: '/ad/create',
        name: 'ad-create',
        meta: {
            title: '添加页面图'
        },
        component: () => import('@/views/ad/Form.vue')
    },
    {
        path: '/ad/update/:id',
        name: 'ad-update',
        meta: {
            title: '修改页面图'
        },
        component: () => import('@/views/ad/Form.vue')
    },
    {
        path: '/ad/set/:id',
        name: 'ad-set',
        meta: {
            title: '编辑页面图'
        },
        component: () => import('@/views/ad/Set.vue')
    },
    {
        path: '/ad/module/:module',
        name: 'ad-module',
        meta: {
            title: '首页设置'
        },
        component: () => import('@/views/ad/Index.vue')
    },


    /*
    |--------------------------------------------------------------------------
    | 配置项
    |--------------------------------------------------------------------------
    */
    {
        path: '/config',
        name: 'config',
        meta: {
            title: '配置项'
        },
        component: () => import('@/views/config/Index.vue')
    },
    {
        path: '/config/create',
        name: 'config-create',
        meta: {
            title: '添加配置项'
        },
        component: () => import('@/views/config/Form.vue')
    },
    {
        path: '/config/update/:id',
        name: 'config-update',
        meta: {
            title: '修改配置项'
        },
        component: () => import('@/views/config/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 用户管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/user',
        name: 'user',
        meta: {
            title: '用户管理'
        },
        component: () => import('@/views/user/Index.vue')
    },
    {
        path: '/user/detail/:id',
        name: 'user-detail',
        meta: {
            title: '用户详情'
        },
        component: () => import('@/views/user/Detail.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 用户余额记录
    |--------------------------------------------------------------------------
    */
    {
        path: '/user-money-records',
        name: 'user-money-records',
        meta: {
            title: '用户余额记录'
        },
        component: () => import('@/views/user-money-records/Index.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 用户提现管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/user-withdraw',
        name: 'user-withdraw',
        meta: {
            title: '用户提现'
        },
        component: () => import('@/views/user-withdraw/Index.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 资讯管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/news',
        name: 'news',
        meta: {
            title: '资讯管理'
        },
        component: () => import('@/views/news/Index.vue')
    },
    {
        path: '/news/create',
        name: 'news-create',
        meta: {
            title: '资讯添加'
        },
        component: () => import('@/views/news/Form.vue')
    },
    {
        path: '/news/update/:id',
        name: 'news-update',
        meta: {
            title: '资讯修改'
        },
        component: () => import('@/views/news/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 会员卡渠道管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/member-card-channel',
        name: 'member-card-channel',
        meta: {
            title: '渠道管理'
        },
        component: () => import('@/views/member-card-channel/Index.vue')
    },
    {
        path: '/member-card-channel/create',
        name: 'member-card-channel-create',
        meta: {
            title: '渠道添加'
        },
        component: () => import('@/views/member-card-channel/Form.vue')
    },
    {
        path: '/member-card-channel/update/:id',
        name: 'member-card-channel-update',
        meta: {
            title: '渠道修改'
        },
        component: () => import('@/views/member-card-channel/Form.vue')
    },


    /*
    |--------------------------------------------------------------------------
    | 会员卡管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/member-card',
        name: 'member-card',
        meta: {
            title: '会员卡管理'
        },
        component: () => import('@/views/member-card/Index.vue')
    },
    {
        path: '/member-card/create',
        name: 'member-card-create',
        meta: {
            title: '会员卡添加'
        },
        component: () => import('@/views/member-card/Form.vue')
    },
    {
        path: '/member-card/update/:id',
        name: 'member-card-update',
        meta: {
            title: '会员卡修改'
        },
        component: () => import('@/views/member-card/Form.vue')
    },
    {
        path: '/member-card-records',
        name: 'member-card-records',
        meta: {
            title: '激活记录'
        },
        component: () => import('@/views/member-card/Records.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 题库分类管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/question-category',
        name: 'question-category',
        meta: {
            title: '题库分类管理'
        },
        component: () => import('@/views/question-category/Index.vue')
    },
    {
        path: '/question-category/create',
        name: 'question-category-create',
        meta: {
            title: '题库分类添加'
        },
        component: () => import('@/views/question-category/Form.vue')
    },
    {
        path: '/question-category/update/:id',
        name: 'question-category-update',
        meta: {
            title: '题库分类修改'
        },
        component: () => import('@/views/question-category/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 题库科目管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/question-subject',
        name: 'question-subject',
        meta: {
            title: '题库科目管理'
        },
        component: () => import('@/views/question-subject/Index.vue')
    },
    {
        path: '/question-subject/create',
        name: 'question-subject-create',
        meta: {
            title: '题库科目添加'
        },
        component: () => import('@/views/question-subject/Form.vue')
    },
    {
        path: '/question-subject/update/:id',
        name: 'question-subject-update',
        meta: {
            title: '题库科目修改'
        },
        component: () => import('@/views/question-subject/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 题库章节管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/question-section',
        name: 'question-section',
        meta: {
            title: '题库章节管理'
        },
        component: () => import('@/views/question-section/Index.vue')
    },
    {
        path: '/question-section/create',
        name: 'question-section-create',
        meta: {
            title: '题库章节添加'
        },
        component: () => import('@/views/question-section/Form.vue')
    },
    {
        path: '/question-section/update/:id',
        name: 'question-section-update',
        meta: {
            title: '题库章节修改'
        },
        component: () => import('@/views/question-section/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 题库管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/question-bank',
        name: 'question-bank',
        meta: {
            title: '题库管理'
        },
        component: () => import('@/views/question-bank/Index.vue')
    },
    {
        path: '/question-bank/create',
        name: 'question-bank-create',
        meta: {
            title: '题库添加'
        },
        component: () => import('@/views/question-bank/Form.vue')
    },
    {
        path: '/question-bank/update/:id',
        name: 'question-bank-update',
        meta: {
            title: '题库修改'
        },
        component: () => import('@/views/question-bank/Form.vue')
    },
    {
        path: '/question-bank-records',
        name: 'question-bank-records',
        meta: {
            title: '答题记录'
        },
        component: () => import('@/views/question-bank/Records.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 学校管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/university',
        name: 'university',
        meta: {
            title: '资讯管理'
        },
        component: () => import('@/views/university/Index.vue')
    },
    {
        path: '/university/create',
        name: 'university-create',
        meta: {
            title: '资讯添加'
        },
        component: () => import('@/views/university/Form.vue')
    },
    {
        path: '/university/update/:id',
        name: 'university-update',
        meta: {
            title: '资讯修改'
        },
        component: () => import('@/views/university/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 学校专业管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/university-major/:university_id',
        name: 'university-major',
        meta: {
            title: '专业管理'
        },
        component: () => import('@/views/university-major/Index.vue')
    },
    {
        path: '/university-major/create/:university_id',
        name: 'university-major-create',
        meta: {
            title: '专业添加'
        },
        component: () => import('@/views/university-major/Form.vue')
    },
    {
        path: '/university-major/update/:id',
        name: 'university-major-update',
        meta: {
            title: '专业修改'
        },
        component: () => import('@/views/university-major/Form.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 试卷管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/exam',
        name: 'exam',
        meta: {
            title: '试卷管理'
        },
        component: () => import('@/views/exam/Index.vue')
    },
    {
        path: '/exam/create',
        name: 'exam-create',
        meta: {
            title: '试卷添加'
        },
        component: () => import('@/views/exam/Form.vue')
    },
    {
        path: '/exam/update/:id',
        name: 'exam-update',
        meta: {
            title: '试卷修改'
        },
        component: () => import('@/views/exam/Form.vue')
    },
    {
        path: '/exam-records',
        name: 'exam-records',
        meta: {
            title: '试卷答题记录'
        },
        component: () => import('@/views/exam/Records.vue')
    },

    /*
    |--------------------------------------------------------------------------
    | 试卷题目管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/exam-question-bank/:exam_id/:question_subject_id',
        name: 'exam-question-bank',
        meta: {
            title: '试卷题目管理'
        },
        component: () => import('@/views/exam-question-bank/Index.vue')
    },
    {
        path: '/exam-question-bank/create/:exam_id/:question_subject_id',
        name: 'exam-question-bank-create',
        meta: {
            title: '试卷题目添加'
        },
        component: () => import('@/views/exam-question-bank/Form.vue')
    },
    {
        path: '/exam-question-bank/update/:id',
        name: 'exam-question-bank-update',
        meta: {
            title: '试卷题目修改'
        },
        component: () => import('@/views/exam-question-bank/Form.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 用户反馈管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/user-feedback',
        name: 'user-feedback',
        meta: {
            title: '用户反馈管理'
        },
        component: () => import('@/views/user-feedback/Index.vue')
    },
    {
        path: '/user-feedback/update/:id',
        name: 'user-feedback-update',
        meta: {
            title: '用户反馈详情'
        },
        component: () => import('@/views/user-feedback/Form.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 视频分类管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/video-category',
        name: 'video-category',
        meta: {
            title: '视频分类管理'
        },
        component: () => import('@/views/video-category/Index.vue')
    },
    {
        path: '/video-category/create',
        name: 'video-category-create',
        meta: {
            title: '视频分类添加'
        },
        component: () => import('@/views/video-category/Form.vue')
    },
    {
        path: '/video-category/update/:id',
        name: 'video-category-update',
        meta: {
            title: '视频分类修改'
        },
        component: () => import('@/views/video-category/Form.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 视频管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/video',
        name: 'video',
        meta: {
            title: '视频管理'
        },
        component: () => import('@/views/video/Index.vue')
    },
    {
        path: '/video/create',
        name: 'video-create',
        meta: {
            title: '视频添加'
        },
        component: () => import('@/views/video/Form.vue')
    },
    {
        path: '/video/update/:id',
        name: 'video-update',
        meta: {
            title: '视频修改'
        },
        component: () => import('@/views/video/Form.vue')
    },
    {
        path: '/video-records',
        name: 'video-records',
        meta: {
            title: '视频播放记录'
        },
        component: () => import('@/views/video/Records.vue')
    },
    /*
    |--------------------------------------------------------------------------
    | 线上会员卡管理
    |--------------------------------------------------------------------------
    */
    {
        path: '/member-card-online',
        name: 'member-card-online',
        meta: {
            title: '线上会员卡管理'
        },
        component: () => import('@/views/member-card-online/Index.vue')
    },
    {
        path: '/member-card-online/create',
        name: 'member-card-online-create',
        meta: {
            title: '线上会员卡添加'
        },
        component: () => import('@/views/member-card-online/Form.vue')
    },
    {
        path: '/member-card-online/update/:id',
        name: 'member-card-online-update',
        meta: {
            title: '线上会员卡修改'
        },
        component: () => import('@/views/member-card-online/Form.vue')
    },
    {
        path: '/member-card-online-records',
        name: 'member-card-online-records',
        meta: {
            title: '线上会员卡激活记录'
        },
        component: () => import('@/views/member-card-online/Records.vue')
    },
    {
        path: '/member-card-online-share',
        name: 'member-card-online-share',
        meta: {
            title: '线上会员卡分享赚'
        },
        component: () => import('@/views/member-card-online/Share.vue')
    },

]